<template>
  <div>

    <select class="form-control" name="idioma" @change="onChangeValor()" v-model="idioma_idSelected">
      <option v-for="item in registros" :value="item.id" :key="item.id">
        {{ item.idioma }}
      </option>
    </select>

  </div>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  props: {
    idiomaId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      registro_id: '',
      idioma_idSelected: '',
      registros: [],
      isLoading: false
    };
  },
  watch: {
    /* Observar cambios en el valor del PROPS y cuando ya tenga busco los datos del registro */
    idiomaId() {
      this.idioma_idSelected = this.idiomaId
      return this.idioma_idSelected
    }
  },
  methods: {
    async getRegistros() {
      this.isLoading = true
      let url = "eco-idiomas"
      let response = await httpService.get(url)
      let respuesta = response.data
      this.registros = respuesta.registros.data
      this.isLoading = false
    },
    onChangeValor() {
      this.registro_id = this.idioma_idSelected
      this.$emit("ActualizandoId", this.idioma_idSelected)
    }
  },
  mounted() {
    this.$emit("ActualizandoId", this.registro_id)
    this.getRegistros()
  }
};
</script>